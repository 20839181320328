<template>
  <div class="flex justify-center w-full">
    <div class="w-1/2">
      <div class="p-5 flex items-center flex-col">
        <div class="my-5 flex flex-col justify-center w-96 ">
          <h3 class="text-primary-dark">
            New password
          </h3>

          <div class="form-control w-96 mt-3">
            <label class="label">
              <span class="label-text text-lg text-primary-dark" />
            </label>
            <input

              v-model="password"
              type="password"
              :class="{'input-error': errorMsg}"
              placeholder="Password"
              class="input input-bordered w-full"
            >
          </div>
          <div class="form-control w-96 mt-3">
            <label class="label">
              <span class="label-text text-lg text-primary-dark" />
            </label>
            <input
              v-model="password2"
              type="password"
              :class="{'input-error': errorMsg}"
              placeholder="Confirm password"
              class="input input-bordered w-full"
            >
          </div>
          <button
            @click="newPassword"

            class="btn bg-accent-green w-full mt-3"
          >
            Done
          </button>
          <p v-if="showLinkExpiredMsg">
            Link expired
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      password2: '',
      errorMsg: false,
      showLinkExpiredMsg: false
    }
  },
  methods: {
    async newPassword () {
      if (this.password !== this.password2) {
        this.errorMsg = true
        return
      }
      const id = this.$route.params.id
      try {
        await this.$api.resetPassword(id, this.password)
        this.$router.push('/login')
      } catch (error) {
        if (error.response.status === 404) {
          this.showLinkExpiredMsg = true
        }
      }
    }
  }
}
</script>

<style></style>
